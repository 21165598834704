import React from 'react'
import './experience.css'
import {FaLaravel} from 'react-icons/fa'
import {SiDotnet,SiNodedotjs,SiPython,SiJavascript,SiJava,SiHtml5,SiCss3,SiReact} from 'react-icons/si'
import {experienceText} from "../../index";


const Experience = (props) => {
  return (
    <section id='experience'>
      <h5>{props.experienceText.experienceTitleSmall[props.language]}</h5>
      <h1>{props.experienceText.experienceTitle[props.language]}</h1>

      <div className="experience_container">

        <div className='experience_front'>
          <h3>Frontend Development</h3>

          <div className='experience_content'>
            {props.experienceText.frontEnd.map((card) => (
                <article className='experience_details'>
                  <SiHtml5 className='experience_details-icon'/>
                  <div className='experience_details-text'>
                    <h4>{card.name}</h4>
                    <small>{card.levelText[props.language]}</small>
                    <div className="experience_details-chart">
                      <div className="experience_details-bar" style={{width: card.level}}></div>
                    </div>
                  </div>
                </article>
                ))}


          </div>
        </div>

        <div className='experience_back'>
          <h3>Backend Development</h3>

          <div className='experience_content'>
            {props.experienceText.backEnd.map((card) => (
                <article className='experience_details'>
                  <SiHtml5 className='experience_details-icon'/>
                  <div className='experience_details-text'>
                    <h4>{card.name}</h4>
                    <small>{card.levelText[props.language]}</small>
                    <div className="experience_details-chart">
                      <div className="experience_details-bar" style={{width: card.level}}></div>
                    </div>
                  </div>
                </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience