import React from 'react'
import Nav from "../components/nav/Nav";
import  "./blog.css"
import ImageSlider from "../components/imageSlider/ImageSlider";
import Footer from "../components/footer/Footer";
import Eng from "../assets/languageFlags/eng.png";
import Nl from "../assets/languageFlags/nl.png";


const BlogPage = (props) => {
    const changeLanguage = () => {
        if (props.language==="nl") {
            props.setLanguage("eng");
        } else {
            props.setLanguage("nl");
        }
    };

        return (
            <>
                <div className="background"></div>
                <div className="content">
            <section id='blogPage'>
            <Nav/>
            <div className="titleHeader">
                <div className="title_container">
                    <div onClick={() => { window.history.back() }} className="backIcon"><span className="backArrow2">❮</span><span className="backArrow1">❮</span>❮</div>
                    <h3>Aron Buffel's blog</h3>
                </div>
                <div className="language_container">
                    <div className='btn-primary' onClick={changeLanguage}>{props.language === 'eng' ? <img src={Eng} className="flag" alt="me"/> : <img className="flag" src={Nl} alt="me"/>}{props.language === 'eng' ? <p>English</p> : <p>Nederlands</p>}</div>
                </div>
            </div>
            <div className="blogPost">
                <div className="titleAndDate">
                    <h4>{props.post.name[props.language]}</h4>
                    <h5>{props.post.date[props.language]}</h5>
                </div>
                <div className="category">
                    <div className="cube"></div>
                    <h6 className="text">{props.post.category[props.language]}</h6>
                </div>
                {props.post.alineas.map((alinea, index)=>(
                        <div className="alinea">
                            {index%2 === 0 ? (
                                <>
                                    <div className="blogPicture">
                                        <div className="imageSlider">
                                            <ImageSlider slides={alinea.alineaImage}/>
                                        </div>
                                    </div>
                                    <div className="blogText">
                                        {alinea.alineaTitle && <h3>{alinea.alineaTitle}</h3>}
                                        {alinea.alineaText && <p>{alinea.alineaText}</p>}
                                        {alinea.alineaText2 && <p>{alinea.alineaText2}</p>}
                                    </div>
                                </>
                            ):(
                                <>
                                    <div className="blogText">
                                        {alinea.alineaTitle && <h3>{alinea.alineaTitle}</h3>}
                                        {alinea.alineaText && <p>{alinea.alineaText}</p>}
                                        {alinea.alineaText2 && <p>{alinea.alineaText2}</p>}
                                    </div>
                                    <div className="blogPicture">
                                        <div className="imageSlider">
                                            <ImageSlider slides={alinea.alineaImage}/>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                ))}
            </div>

            </section>
                <Footer language={props.language} footerNavText={props.footerNavText}/>
                </div>
            </>
          );
    }
export default BlogPage;