
import React, {useEffect, useState} from 'react'
import './nav.css'
import {FiHome,FiUser,FiMessageCircle,} from 'react-icons/fi'
import {CgWebsite} from 'react-icons/cg'
import {GiBrain} from 'react-icons/gi'


const Nav = (props) => {

  return (
    <nav>
      <a href='/#'><FiHome/></a>
      <a href='/#about'><FiUser/></a>
      <a href='/#experience'><GiBrain/></a>
      <a href='/#portfolio'><CgWebsite/></a>
      <a href='/#contact'><FiMessageCircle/></a>
    </nav>
  )
}

export default Nav
//className={activeSection === 'header' ? 'active' : ''}