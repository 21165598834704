
import {FiLinkedin,FiGithub,FiInstagram,FiFacebook} from 'react-icons/fi'

import React, { useState } from "react";
import "../../pages/icon.css";




const HeaderSocials = () => {
  return (
    <div className='header_socials'>
            <a href='https://linkedin.com/in/buffel-aron' target="_blank"><FiLinkedin size={20}/></a>
            <a href='https://github.com' target="_blank"><FiGithub size={20}/></a>
            <a href='https://instagram.com/aronbu' target="_blank"><FiInstagram size={20}/></a>
            <a href='https://facebook.com/aron.buffel' target="_blank"><FiFacebook size={20}/></a>


    </div>
  )
}

export default HeaderSocials