
import React, { useState } from "react";
import "./icon.css";
import {FiFacebook} from "react-icons/fi";

const Icon = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
        setPosition({ x: e.clientX, y: e.clientY });
    };

    return (

        <div
            className="icon-container"
            onMouseMove={handleMouseMove}
            onMouseLeave={() => setPosition({ x: 0, y: 0 })}
        >
            <div
                className="icon"
                style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
            >
                <FiFacebook size={20}/>
            </div>
        </div>
    );
};

export default function App() {
    return (
        <div className="parent">
            <Icon />
        </div>
    );
}
