import React from 'react'
import './about.css'
import aboutMePicture from '../../assets/aboutMePicture.jpg'
import {FaUserGraduate} from 'react-icons/fa'
import {MdOutlinePrecisionManufacturing} from 'react-icons/md'
import {HiOutlineCode} from 'react-icons/hi'


const About = (props) => {
  return (
    <section id='about'>
      <h5>{props.aboutText.aboutTitleSmall[props.language]}</h5>
      <h1>{props.aboutText.aboutTitle[props.language]}</h1>

      <div className="about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={aboutMePicture} alt="about image"/>
          </div>
        </div>

        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FaUserGraduate className='about_icon'/>
              <h5>{props.aboutText.aboutTile1Title[props.language]}</h5>
              <small>{props.aboutText.aboutTile1Text[props.language]}</small>
            </article>

            <article className="about_card">
              <MdOutlinePrecisionManufacturing className='about_icon'/>
              <h5>{props.aboutText.aboutTile2Title[props.language]}</h5>
              <small>{props.aboutText.aboutTile2Text[props.language]}</small>
            </article>

            <article className="about_card">
              <HiOutlineCode className='about_icon'/>
              <h5>{props.aboutText.aboutTile3Title[props.language]}</h5>
              <small>{props.aboutText.aboutTile3Text[props.language]}</small>
            </article>
          </div>
          <p className="about_text">{props.aboutText.aboutText1[props.language]}</p>
          <p className="about_text">{props.aboutText.aboutText2[props.language]}</p>
          <p className="about_text">{props.aboutText.aboutText3[props.language]}</p>
          <p className="about_text">{props.aboutText.aboutText4[props.language]}</p>
          <a href='#contact' className='btn-primary'>{props.aboutText.aboutContact[props.language]}</a>
        </div>
      </div>

    </section>
  )
}

export default About

