
import React, {useEffect, useState} from 'react'


import Header from "../components/header/Header";
import Nav from "../components/nav/Nav";
import About from "../components/about/About";
import Experience from "../components/experience/Experience";
import Portfolio from "../components/portfolio/Portfolio";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";

const  Home = (props) => {
    const [activeSection, setActiveSection] = useState('');

    const handleScroll = () => {
        const sections = document.querySelectorAll('.section');
        sections.forEach(section => {
            const rect = section.getBoundingClientRect();
            if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                setActiveSection(section.id);
            }
        });
        console.log(activeSection);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div className={props.lightMode?'background light':'background'}></div>
            <div className={props.lightMode?'content light':'content'}>
                <Header language={props.language} setLanguage={props.setLanguage} headerText={props.headerText}
                        lightMode={props.lightMode} setLightMode={props.setLightMode}/>
                <Nav language={props.language} activeSection={activeSection}/>
                <About language={props.language} aboutText={props.aboutText}/>
                <Experience language={props.language} experienceText={props.experienceText}/>
                <Portfolio language={props.language} portfolioText={props.portfolioText} posts={props.posts}/>
                <Contact language={props.language} contactText={props.contactText}/>
                <Footer language={props.language} footerNavText={props.footerNavText}/>
            </div>

        </>
    )
}
export default Home