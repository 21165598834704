import React, {useEffect, useRef, useState} from 'react'

import  "./imageSlider.css"
import {BsArrowLeftSquareFill, BsArrowRightSquareFill} from "react-icons/bs";
import {ImCross} from "react-icons/im";



const ImageSlider = ({slides}) => {
    const [current,setCurrent]= useState(0);
    const [visible, setVisible] = useState(true);
    const [backClicked, setBackClicked] = useState(false);
    const [nextClicked, setNextClicked] = useState(false);




    const slideNextButton = () => {
        slideNext();
        setNextClicked(true);
        setTimeout(() => {
            setNextClicked(false);
        }, 350);

    }
    const slideNext = () => {
        if(current===slides.length-1){
            setCurrent(0);
        }else{
            setCurrent(current+1);
        }
    }
    const slideBackButton = () => {
        slideBack();
        setBackClicked(true);
        setTimeout(() => {
            setBackClicked(false);
        }, 350);

    }
    const slideBack = () => {
        if(current===0){
            setCurrent(slides.length-1);
        }else{
            setCurrent(current-1);
        }
    }

    //make arrows visable first seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    //for autoplay video
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleVideoClick = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    //variable to view fullscreen
    const [isViewingMedia, setIsViewingMedia] = useState(false);
    const [viewingMediaIndex, setViewingMediaIndex] = useState(0);

    //for image slider autoplay
    useEffect(() => {
        const timer = setTimeout(() => {
            slideNext();
        }, 12500);

        return () => {
            clearTimeout(timer);
        };
    }, [current]);

    return(
        <div className="carousel">
            <div className="carouselWrapper">
                {slides.map((image,index)=>{
                    return(
                        <div key={index} className={index===current ? "carouselCard carouselCardActive":"carouselCard"}>

                            {image.url.endsWith(".mp4") ? (
                                <video ref={videoRef} controls={false} onClick={handleVideoClick} className="cardImage" autoPlay={true} loop={true} muted={true}>
                                    <source src={image.url} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img src={image.url} alt={image.title} className="cardImage" onClick={() => {setIsViewingMedia(true); setViewingMediaIndex(index);}}/>
                            )}
                            <div className="carouselCardOverlay">
                                <div className="carouselCardOverlayText">
                                    <h6 className={visible ?"carouselCardClick carouselCardClickVisable":"carouselCardClick carouselCardClickHidden"}>klik voor fullscreen</h6>
                                    <h3 className="carouselCardTitle">{image.title}</h3>
                                </div>
                            </div>
                        </div>
                    )
                    })
                }

                <div className={visible ? backClicked ?"carouselBackArrow clicked showArrows":"carouselBackArrow showArrows":backClicked ?"carouselBackArrow clicked showArrows":"carouselBackArrow"} onClick={slideBackButton}><BsArrowLeftSquareFill/></div>
                <div className={visible ? nextClicked ?"carouselNextArrow clicked showArrows":"carouselNextArrow showArrows":nextClicked ?"carouselNextArrow clicked showArrows":"carouselNextArrow"} onClick={slideNextButton}><BsArrowRightSquareFill/></div>
                <div className="carouselDots">
                    {
                        slides.map((_,index)=>{
                            return(
                                <div key={index} className="carouselDotCover" onClick={()=>setCurrent(index)}>
                                    <div className={index===current ? "carouselDot carouselDotActive":"carouselDot"}></div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {isViewingMedia && (
                <div className="mediaViewer">
                    {slides[current].url.endsWith(".mp4") ? (
                        <video src={slides[current].url} controls={true} className="fullSizeMedia" autoPlay={true} loop={true} muted={true}></video>
                    ) : (
                        <img src={slides[current].url} alt={slides[current].title} className="fullSizeMedia" />
                    )}
                    <button className="closeBtn" onClick={() => setIsViewingMedia(false)}><ImCross/></button>
                    <div className={backClicked ?"fullscreenBackArrow clicked":"fullscreenBackArrow"} onClick={slideBack}><BsArrowLeftSquareFill/></div>
                    <div className={nextClicked ?"fullscreenNextArrow clicked":"fullscreenNextArrow"} onClick={slideNext}><BsArrowRightSquareFill/></div>
                </div>
            )}
        </div>

    );
}
export default ImageSlider;