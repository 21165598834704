import React from 'react'
import './portfolio.css'

const Portfolio = (props) => {
    function clickBlogTile(id) {
        window.location.href = '/blog/'+id;
    }

    return (
    <section id='portfolio'>
      <h5>{props.portfolioText.portfolioTitleSmall[props.language]}</h5>
      <h1>{props.portfolioText.portfolioTitle[props.language]}</h1>

      <div className="portfolio_container">
        {props.posts.map((post) => (
            <article className="portfolio_item" onClick={() => clickBlogTile(post.id)}>
              <div className='portfolio_item-image'>
                <div>
                  <img src={post.thumbnail} alt=""></img>
                </div>
              </div>
              <div className='portfolio_item-overlay'>
                  <div className='portfolio_item-overlay-text'>
                <h4 className="blogArticleCategory">{post.category[props.language]}</h4>
                <h3 className="blogArticleTitle">{post.name[props.language]}</h3>
                <h5 className="blogArticleDate">{post.date[props.language]}</h5>
                <div className='portfolio_item-cta'>
                  <a href={`/blog/${post.id}`} className='btn'>{props.portfolioText.portfolioMore[props.language]}</a>
                </div>
                  </div>
              </div>
            </article>
            ))}



      </div>
    </section>
  )
}

export default Portfolio