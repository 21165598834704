import React from 'react'
import CV from '../../assets/Buffel_Aron_cv.pdf'
import './buttons.css'
const Buttons = (props) => {
    function goToContact() {
        window.location.href = '#contact';
    }
    function downloadCV() {
        window.location.href = CV;
    }
  return (
    <div className='buttons'>
        <button className="gb gb-bordered hover-slide hover-fill" id="gb1" onClick={downloadCV}>
            {props.headerText.headerCV[props.language]}</button>
        <button className="gb gb-bordered hover-slide hover-fill reverse" id="gb1" onClick={goToContact}>
            {props.headerText.headerContact[props.language]}</button>
    </div>
  )
}

export default Buttons