
import './header.css'
import Buttons from './Buttons'
import HeaderSocials from './HeaderSocials'
import ProfilePicture from '../../assets/profilePicture.png'
import Nl from '../../assets/languageFlags/nl.png'
import Eng from '../../assets/languageFlags/eng.png'
import React, { useRef, useEffect } from 'react';
import {BsMoonStarsFill,BsSun} from "react-icons/bs";



const Header = (props) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // set canvas dimensions
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;

    // set up characters and colors
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789あいうえおかきくけこさしすэюёж./§µ¨^°)]{';
    //ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789
    const colors = ['#0F0', '#0F0', '#0F0', '#0F0', '#0F0'];
    const font_size = 10;

    const columns = canvas.width / font_size;
    const drops = [];

    // initialize drops at the top of the canvas
    for (let i = 0; i < columns; i++) {
      drops[i] = 1;
    }

    const draw = () => {
      // set background color and opacity
      ctx.fillStyle = 'rgba(1,1,1,0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // loop over drops
      for (let i = 0; i < drops.length; i++) {
        // randomly choose a character and color
        const character = characters[Math.floor(Math.random() * characters.length)];
        const color = colors[Math.floor(Math.random() * colors.length)];

        // draw the character
        ctx.fillStyle = color;
        ctx.fillText(character, i * font_size, drops[i] * font_size);

        // reset drops when they reach the bottom of the canvas
        if (drops[i] * font_size > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }

        // increment drop position
        drops[i]++;
      }
    };

    // set up animation loop
    const interval = setInterval(() => {
      draw();
    }, 33);

    return () => clearInterval(interval);
  }, []);

  const changeLanguage = () => {
    if (props.language==="nl") {
      props.setLanguage("eng");
    } else {
      props.setLanguage("nl");
    }
  };

  const changeLightMode = () => {
    if (props.lightMode===false) {
      props.setLightMode(true);
    } else {
      props.setLightMode(false);
    }
  };

  return (
      <header>
        <div className="language_container">
          <div className='btn-primary' onClick={changeLanguage}>{props.language === 'eng' ? <img src={Eng} className="flag" alt="me"/> : <img className="flag" src={Nl} alt="me"/>}{props.language === 'eng' ? <a>English</a> : <a>Nederlands</a>}</div>
          <div className='lightModeIcon' onClick={changeLightMode}>
            {props.lightMode === true
                ? <BsSun/>
                : <BsMoonStarsFill/>
            }
          </div>
        </div>
        <div className="container header_container">
          <h5>{props.headerText.headerGreeting[props.language]}</h5>
          <h1>{props.headerText.headerName}</h1>
          <h5 className="text-light">{props.headerText.headerProffesion}</h5>
          <Buttons language={props.language} headerText={props.headerText}/>
          <HeaderSocials/>
          <div className="me">
            <div className="me-image">
              <img src={ProfilePicture} alt="me"/>
              <canvas ref={canvasRef}/>
            </div>
          </div>

          <a href='#contact' className='scroll_down'>{props.headerText.headerScroll[props.language]}</a>
        </div>
      </header>
  )
};


export default Header