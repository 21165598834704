
import './footer.css'
import {FiLinkedin,FiGithub,FiInstagram,FiFacebook} from 'react-icons/fi'
import React, { useRef, useEffect,useState } from 'react';

const Footer = (props) => {
    const [buttonLinkedinClicked, setButtonLinkedinClicked] = useState(false);
    const [buttonInstagramClicked, setButtonInstagramClicked] = useState(false);
    const [buttonFacebookClicked, setButtonFacebookClicked] = useState(false);
    const [buttonGithubClicked, setButtonGithubClicked] = useState(false);
    const Button = (sort) => {
        if(sort==="linkedin"){
            setButtonLinkedinClicked(true);
            setTimeout(() => {
                setButtonLinkedinClicked(false);
            }, 350);
            setTimeout(() => {
                openLinkedin();
            }, 450);
        }else if(sort==="instagram"){
            setButtonInstagramClicked(true);
            setTimeout(() => {
                setButtonInstagramClicked(false);
            }, 350);
            setTimeout(() => {
                openInstagram();
            }, 450);
        }else if(sort==="facebook"){
            setButtonFacebookClicked(true);
            setTimeout(() => {
                setButtonFacebookClicked(false);
            }, 350);
            setTimeout(() => {
                openFacebook();
            }, 450);
        }else if(sort==="github"){
            setButtonGithubClicked(true);
            setTimeout(() => {
                setButtonGithubClicked(false);
            }, 350);
            setTimeout(() => {
                openGithub();
            }, 450);
        }
    }

    function openLinkedin() {
        window.open('https://linkedin.com/in/buffel-aron', '_blank');
    }
    function openGithub() {
        window.open('https://github.com/', '_blank');
    }
    function openInstagram() {
        window.open('https://www.instagram.com/aronbu/', '_blank');
    }
    function openFacebook() {
        window.open('https://www.facebook.com/aronbuffel/', '_blank');
    }

  return (
    <footer>
      <a href='#' className='footer_name'>Aron Buffel</a>
      <ul className='permalinks'>
        <li><a href='/#'>{props.footerNavText.home[props.language]}</a></li>
        <li><a href='/#about'>{props.footerNavText.about[props.language]}</a></li>
        <li><a href='/#experience'>{props.footerNavText.experience[props.language]}</a></li>
        <li><a href='/#portfolio'>{props.footerNavText.portfolio[props.language]}</a></li>
        <li><a href='/#contact'>{props.footerNavText.contact[props.language]}</a></li>
      </ul>

      <div className="footer_socials">
          <a onClick={() => Button("linkedin")} className={buttonLinkedinClicked?"footerButtonClicked":""}><FiLinkedin size={20}/></a>
          <a onClick={() => Button("github")} className={buttonGithubClicked?"footerButtonClicked":""}><FiGithub size={20}/></a>
          <a onClick={() => Button("instagram")} className={buttonInstagramClicked?"footerButtonClicked":""}><FiInstagram size={20}/></a>
          <a onClick={() => Button("facebook")} className={buttonFacebookClicked?"footerButtonClicked":""}><FiFacebook size={20}/></a>
    </div>

      
    </footer>
  )
}

export default Footer