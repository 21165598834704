import React from 'react'
import './contact.css'
import '../about/about.css'
import {FiPhone,FiLinkedin,FiMail} from 'react-icons/fi'

const Contact = (props) => {
  return (
    <section id='contact'>
      <h5>{props.contactText.contactTitleSmall[props.language]}</h5>
      <h1>{props.contactText.contactTitle[props.language]}</h1>
      <div className="about_content">
      <div className="about_cards">
            <article className="about_card">
              <FiMail className='about_icon'/>
              <h5>{props.contactText.contactEmailTitle}</h5>
              <p className="email">{props.contactText.contactEmailWork}</p>
              <p className="email">{props.contactText.contactEmailPersonal}</p>
            </article>

            <article className="about_card">
              <FiPhone className='about_icon'/>
              <h5>{props.contactText.contactPhoneTitle[props.language]}</h5>
              <p>{props.contactText.contactPhone}</p>
            </article>

            <article className="about_card">
              <FiLinkedin className='about_icon'/>
              <h5>{props.contactText.contactLinkedinTitle}</h5>
              <small>{props.contactText.contactLinkedin}</small>
            </article>
          </div>
        </div>
    </section>
  )
}

export default Contact