
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from 'react-dom/client';

import './index.css'
import Home from "./pages/Home";
import BlogPage from "./pages/BlogPage";
import NotFound from "./pages/NotFound";
import React, {useState} from 'react'
import Icon from "./pages/Icon";


export const posts = [
    {
        id: 1,
        name: {
            'nl': "Howest Goes USA trip naar San Francisco",
            'eng': "Howest Goes USA trip to San Francisco"
        },
        category: {
            'nl': "Schoolreis / Bedrijfsbezoeken",
            'eng': "School trip / Company visits"
        },
        date: {
            'nl': "3 april 2023 - 11 april 2023",
            'eng': "3 april 2023 - 11 april 2023"
        },
        thumbnail: "/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftGroup.jpg",
        alineas: [
            {
                alineaTitle: 'Inleiding',
                alineaText: 'Dit jaar organiseerde Howest opnieuw de trip naar San Francisco in de USA, na een 3 jarige stop wegens corona. Het verheugde me enorm om te vernemen dat de reis dit jaar weer zou plaatsvinden. Toen we dit hoorden hadden een paar vrienden (Jonathan Depaepe en Yarne Savaete) en ik ons meteen ingeschreven. We waren ook erg opgelucht om het nieuws te horen dat we mee mochten gaan.',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Inleiding/ScenerySunset3.jpg", title:"Skyline van SF met zonsondergang"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Inleiding/ScenerySkyline4.jpg", title:"Straat met uitzicht op Oakland Bay Bridge"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Inleiding/ScenerySkyline3.jpg", title:"Straat in downtown San Francisco"}
                ]
            },
            {
                alineaTitle: 'Dag 1',
                alineaText: 'Het startte s ’morgensvroeg op maandag 2 april in Brugge, met het verzamelen van de Howest studenten uit Brugge. Toen reed de bus door naar Kortrijk om daar de andere Howest studenten uit Kortrijk op te halen en de studenten van Devine. Toen begon de reis echt met eerst een 6 uur lange busrit naar de luchthaven van Parijs. Waar we toen onze 12 uur lange vlucht moesten nemen naar San Francisco. Toen we na 12 uur landde in San Francisco was het nog steeds ochtend in de zelfde dag terwijl er vele uren gepasseerd waren, toch wel raar die jetlag.',
                alineaText2: 'Het eerste wat we deden in San Francisco was een rit naar onze hostel, waar we onze bagage al konden plaatsen voor dat we begonnen met een stadswandeling om de buurt wat te verkennen. In deze tour hadden we voor het eerst de typische “cable cars” gebruikt. Dit is wel iets heel apart, hiervan bestuurder zijn het lijkt mij ook wel nog een moeilijke job. We liepen ook door typische San Francisco-plaatsen met steile hellingen en mooie uitzichten. De wandeling was uiteindelijk naar één van de vele pieren, “Pier 39” dit is een pier met veel restaurants en entertainment. Naast de pier is er ook een plaats waar zeeleeuwen zich verzamelen.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag1/GroupPhotoUnionSquare.jpg", title:"Groepsfoto Union Square"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag1/ScenerySkyline2.jpg", title:"Skyline van San Francisco van ver"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag1/ScenerySkyline5.jpg", title:"James C. Flood Mansion en de Grace kathedraal"}
                ]
            },
            {
                alineaTitle: 'Dag 2',
                alineaText: 'De tweede dag startte met een wandeling over de Golden Gate Bridge. Op de brug hadden we zeer mooie zichten van de stad met de skyline van alle skyscrapers. Nadat we de brug hadden overgestoken zaten we weer op de bus richting Silicon Valley voor ons eerste bedrijfsbezoek bij Palo Alto Networks. Hier kregen we eerst een briefing over het bedrijf, hier leerden we dat Palo Alto een netwerk bedrijf is waar security vooral centraal staat. Hier kregen ook nog een paar medewerkers te horen, ze deelden met ons wat over hun werkervaring en ook wat tips. Deze tips waren wel erg informatief omdat een deel ervan ging over het zoeken en solliciteren voor IT jobs .Toen kregen we ook een rondleiding in de campus, dit was een erg grote campus met vele gebouwen en verschillende accommodaties zoals vele eetkraampjes, een fitness ,yoga en sportlessen.',
                alineaText2: 'Na het eerste bedrijfsbezoek waren we even vrij om te eten. Toen heb ik voor het eerst In-And-Out kunnen eten. Ik had dit altijd al wel eens willen eten wat dit zag er als de beste burgers uit op sociale media. En het was totaal geen teleurstelling, de burgers waren zeer goed.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/SceneryGoldenGateBridge.jpg", title:"Golden Gate Bridge"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/PaloAltoGroup.jpg", title:"Palo Alto Networks groepsfoto"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/PaloAltoOffice1.jpg", title:"Palo Alto Networks campus"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/PaloAltoOffice2.png", title:"Palo Alto Networks Building 1"}
                ]
            },
            {
                alineaTitle: null,
                alineaText: 'Na de lunch zaten we weer op de bus naar ons tweede bedrijfsbezoek deze keer was dit bij Microsoft. Hier kregen we een briefing door Nills en Emilio. In deze briefing ging eerst even over hun persoonlijke carrière. Nills de persoon die vanuit België verhuisde, gaf vooral tips over hoe je een carrière in de VS kan starten, Emilio gaf tips hoe je op wat oudere leeftijd nog steeds je leven kunt omkeren en zo toch een goede job in big tech job te verkrijgen. Toen ging het vooral over AI en low code. Hier waren ook veel vragen uit de groep, hierop hebben we veel nuttige antwoorden en tips gekregen. De vragenronde was zelf zo lang uitgelopen dat er geen tijd meer over was voor een rondleiding in de campus. Wat ook erg interessant was hier was wat meer info over de samenwerkingen tussen OpenAI en Microsoft.',
                alineaText2: 'Na het bezoek aan Microsoft waren we doorgereden naar het Apple Visitor Center waar we een rondleiding kregen met wat info over het Apple en hun campus. In de Visitor Center is er ook een miniatuur van de Apple campus waarmee je dan met een iPad kan op inzoomen in Augmented Reality.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftGroup.jpg", title:"Microsoft groepsfoto"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftOffice.jpg", title:"Microsoft campus"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftMeetingRoom.jpg", title:"Microsoft meeting room"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftSign.jpg", title:"Microsoft logo"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/AppleVisitor.jpeg", title:"Apple Visitor Center"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/AppleOffice.jpeg", title:"Apple campus"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/AppleVisitorAugmentedReality1.jpeg", title:"Apple miniatuur campus met AR"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/AppleVisitorAugmentedReality2.gif", title:"Apple miniatuur campus met AR"}
                ]
            },
            {
                alineaTitle: 'Dag 3',
                alineaText: 'De derde dag startte met een Alumni event waar enkele Belgen die verhuisd zijn naar Amerika hun ervaringen en tips deelden. De eerste spreker was Thomas, hij is één van de Belgen die verhuisd is naar de Verengde Staten om te werken in Silicon Valley voor OpenAI. Hij vertelde zijn verhaal hoe dit allemaal is verlopen en deelde enkele tips die hij zelf heeft ondervonden tijdens zijn carrière. Hij toonde ook de site van Stripe, hieraan heeft hij gewerkt aan de animaties. Dit was toch echt wel een indrukwekkende site met de meest gedetailleerde animaties die ik ooit gezien heb. Zoals alles van voorbeeldprijzen op de site veranderen op basis van locatie en hoe duur de prijzen in die regio zijn.',
                alineaText2: 'De andere spreker die kwam is Christean, hij is ex Pinterest medewerker en werkt nu voor een nieuwe startup genaamd Cambly. Hij deed ook zijn verhaal over zijn carrière en gaf ook wat tips. De tips van beide waren wel erg interessant ze gingen voornamelijk over hoe je het beste kan maken van je carrière, zoals dat een netwerk bouwen erg belangrijk is en dat zelf geïnteresseerd zijn buiten het vak ook vele voordelen biedt.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/SceneryPaintedLadies.jpg", title:"Painted Ladies"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/ScenerySkyline1.jpg", title:"San Francisco's skyline"}
                ]
            },
            {
                alineaTitle: null,
                alineaText: 'Achter de lunchpauze pakten we de BART naar Berkeley om de universiteit te bezoeken. De BART is de metro in San Francisco, en maakt de verbinding tussen downtown San Francisco en omstreken. Op de universiteit kregen we een rondleiding van enkele van de studenten die hier studeren. Dit was een erg grote campus met vele grote gebouwen voor specifieke doelen zoals: een gebouw voor de bibliotheek, een voor “physiology”, een voor “engineering” en nog vele meer.',
                alineaText2: 'Dit was een erg mooie campus maar voor het prijskaartje van enkele tienduizenden dollar per jaar zou ik dit er persoonlijk niet voor overhebben. Toch wel blij dat het Belgische onderwijs niet zo prijzig is.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley1.jpg", title:"California University at Berkeley"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley2.jpg", title:"California University at Berkeley"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley3.jpg", title:"California University at Berkeley"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley4.jpg", title:"California University at Berkeley"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley5.jpg", title:"California University at Berkeley"}
                ]
            },
            {
                alineaTitle: 'Dag 4',
                alineaText: 'We startte de vierde dag met een wandeling lang de Painted Ladies en dan nog een stop op de Twin Peaks waarop je een mooie panorama zicht hebt over de hele stad. Hier waren we ook nog even vrij om rond te wandelen.',
                alineaText2: 'Hierna namen we terug de bus naar Silicon Valley, deze keer om Odoo te bezoeken. Dit is een ERP systeem dat erg is geëvolueerd over de tijd. Het was oorspronkelijk een klein programma maar is uit gegroeid tot een erg groot programma met veel integraties. Het is ook een Belgisch bedrijf dat is gestart in Waterschei. Ook hier hebben we vele tips gekregen die we later zullen kunnen gebruiken. Zoals eerst kleiner starten en daarna de scope vergroten en blijven evolueren.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/ScenerySkyline2.jpg", title:"Skyline van San Francisco van ver"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/SceneryPaintedLadies.jpg", title:"Painted Ladies"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OdooOffice1.jpg", title:"Odoo kantoor"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OdooOffice2.jpg", title:"Odoo kantoor"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OdooSign.jpg", title:"Odoo logo"}
                ]
            },
            {
                alineaTitle: null,
                alineaText: 'Na de middag deden we nog een bedrijfsbezoek aan Oracle. Dit was een erg mooie campus met veel gebouwen en ook een van de sportzeilboten op display. Hier kregen we een voorstelling over Oracle en hun nieuwe low code software, waarmee je gemakkelijk web apps kan maken zonder te programmeren.',
                alineaText2: 'Hierna deden we nog een wandeling door de Castro wijk en door Mission Dolores Park. Dit is altijd leuk om nog wat van de voor ons nieuwe stad San Francisco te verkennen.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/GroupPhotoOracle.jpg", title:"Groepsfoto Oracle"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OracleOffice1.jpg", title:"Oracle kantoor"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OracleOffice2.jpg", title:"Oracle kantoor"}
                ]
            },
            {
                alineaTitle: 'Dag 5',
                alineaText: 'De vijfde dag startte met een wandeling door de stad en een bezoek aan het Salesforce Park. Daarna liep de wandeling ook langs het Oracle Park Stadium.',
                alineaText2: 'In deze buurt was ook ons volgend bedrijfsbezoek aan CloudFlare voorzien. Hier was ook iets erg interessant hun manier om randomness te bepalen is aan de hand van vele lavalampen. Hier kregen we ook een office tour en ook een voorstelling over CloudFlare en hun services. Dit was ook erg interessant en ook weer vele tips kunnen halen uit mensen met veel ervaring. Het was een hele ervaring om een van de grootse netwerk en hosting bedrijven te kunnen bezoeken.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag5/CloudFlareLavalamps.jpg", title:"CloudFlare lavalampen"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag5/CloudFlareOffice1.jpg", title:"CloudFlare kantoor"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag5/CloudFlareOffice2.jpg", title:"CloudFlare kantoor"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag5/ScenerySkyline6.jpg", title:"Salesforce Park"}
                ]
            },
            {
                alineaTitle: 'Dag 6',
                alineaText: 'De zesde dag waren we vrij en hadden we besloten om een baseball match te gaan bekijken. Deze match was de San Francisco Giants versus de Kansas City Royals. Dit was wel een erg leuke ervaring die ik waarschijnlijk niet meer zal meemaken hier in Europa. Het is toch wel iets anders dan voetbal.',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag6/BaseballOraclePark.jpg", title:"Oracle Park"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag6/BaseballPlayers.jpg", title:"Giants spelers"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag6/BaseballOracleParkVideo.mp4", title:"Baseball"}
                ]
            },
            {
                alineaTitle: 'Dag 7',
                alineaText: 'De zevende dag startte ik vroeg in de morgen met een ochtendwandeling, het was namens net de dag dat het San Francisco henge is. Dit is wanneer het pad van de zon op de zelfde lijn loopt als het stratenpatroon van San Francisco. Dit zorgde voor een paar mooie foto’s van het uitzicht.',
                alineaText2: 'Ook op deze dag deden we een bezoek aan Alcatraz een oude streng bewaakte gevangenis uit de ' +
                    'Verenigde Staten. Het is namelijk op een eiland gelegen zodanig de gevangenen goed vast ' +
                    'zitten. De gevangenis was erg groot met erg kleine cellen voor de gevangenen. We waren vrij ' +
                    'op het eiland om rond te lopen en verkennen. In het gevangenis gebouw was er ook een audiotour.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/ScenerySunset1.jpg", title:"San Francisco Henge"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/ScenerySunset2.jpg", title:"San Francisco Henge"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/AlcatrazIsland1.jpg", title:"Alcatraz gevangenis eiland"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/AlcatrazIsland2.jpg", title:"Alcatraz gevangenis eiland"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/AlcatrazMiniature.jpg", title:"Alcatraz miniatuur"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/AlcatrazPrisonEntrance.jpg", title:"Alcatraz gevangenis ingang"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/ChinatownStreet.jpg", title:"Chinatown"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/ChinatownBuilding.jpg", title:"Chinatown"}
                ]
            }
        ]
    },
    {
        id: 2,
        name: {
            'nl': "Hack the future 2022",
            'eng': "Hack the future 2022"
        },
        category: {
            'nl': "Hackaton",
            'eng': "Hackaton"
        },
        date: {
            'nl': "11 november 2022",
            'eng': "11 november 2022"
        },
        thumbnail: "/blogImages/HackTheFuture2022/thumbnail.jpg",
        alineas: [
            {
                alineaTitle: 'Inleiding',
                alineaText: 'Samen met Jonathan Depaepe nam ik deel aan Hack the Future 2022. De challenge die wij hadden gekozen was “plant de toekomst”. In deze challenge moesten de deelnemende teams elk een website en back-end bouwen waar de bezoekers echte bomen konden planten om het milieu te helpen en deze toevoegen aan hun eigen virtueel bos op hun profiel. De technologieën die moesten worden gebruikt waren een ReactJS front-end en een Java Spring back-end.',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/HackTheFuture2022/Slider1/1668549027683.jpg", title:"Howest groepsfoto"},
                    {url:"/blogImages/HackTheFuture2022/Slider1/hack_the_future_2022_-_aftermovie (1080p).mp4", title:"Aftermovie"}
                 ]
            },
            {
                alineaTitle: 'Onze ervaring',
                alineaText: 'Dit wat een heel leuke ervaring waar ik heel veel heb bijgeleerd op een erg korte tijd. De coaches van Optis, het bedrijf die deze challenge organiseerde waren altijd klaar om hulp te verlenen. Tijdens het coderen aan het project hadden Jonathan en ik het werk grotendeels verdeeld, Jonathan werkte meestal aan het Java Spring back-end gedeelte en ik aan de front-end in ReactJS.  Na een hele dag aan onze website te hebben gewerkt waren we erg trots op ons resultaat. Screenshots van deze website kan u in de bijlage afbeeldingen terug vinden.',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/HackTheFuture2022/Slider2/52506356505_cc8d1c6024_o.jpg", title:"Foto prijsuitreiking"},
                    {url:"/blogImages/HackTheFuture2022/Slider2/52521250693_862a8241cc_k.jpg", title:"Podium van Hack The Future"}
                    ]
            },
            {
                alineaTitle: 'Prijsuitreiking',
                alineaText: 'Tijdens de prijsuitreiking waren er 2 prijzen te winnen per challange, namelijk de juryprijs en de publieksfavoriet. Eerst dachten we dat we niet zo veel kans gingen maken maar toen we zagen dat we bij de genomineerden waren begon de hoop te stijgen. Toen werd onze naam afgeroepen als winnaar voor de publiekprijs, we zijn erg blij met deze prijs.',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/HackTheFuture2022/slider3/52521184025_43dd8c3a68_o.jpg", title:"Foto tijdens Hack The Future challenge"},
                    {url:"/blogImages/HackTheFuture2022/slider3/52506490615_b4ef53b7e3_k.jpg", title:"Hang in de Handelsbeurs in Antwerpen"}
                ]
            }
        ]
    },
    {
        id: 3,
        name: {
            'nl': "Gastles “Studio Emma”",
            'eng': "Guestlecture “Studio Emma”"
        },
        category: {
            'nl': "Gastles",
            'eng': "Guestlecture"
        },
        date: {
            'nl': "11 november 2022",
            'eng': "11 november 2022"
        },
        thumbnail: "/blogImages/GastlesEmma/thumbnail.jpg",
        alineas: [
            {
                alineaTitle: 'Inleiding',
                alineaText: 'Dit jaar organiseerde Howest opnieuw de trip naar San Francisco in de USA, na een 3 jarige ' +
                    'stop wegens corona. Het verheugde me enorm om te vernemen dat de reis dit jaar weer zou ' +
                    'plaatsvinden. Toen we dit hoorden hadden een paar vrienden (Jonathan Depaepe en Yarne ' +
                    'Savaete) en ik ons meteen ingeschreven. We waren ook erg opgelucht om het nieuws te horen ' +
                    'dat we bij de groep zaten die mee mochten.',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Inleiding/ScenerySunset3.jpg", title:"Skyline van SF met zonsondergang"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Inleiding/ScenerySkyline4.jpg", title:"Straat met uitzicht op Oakland Bay Bridge"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Inleiding/ScenerySkyline3.jpg", title:"Straat in downtown San Francisco"}
                ]
            },
            {
                alineaTitle: 'Dag 1',
                alineaText: 'Het startte smorgensvroeg in Brugge op maandag 2 april, met het verzamelen van de Howest\n' +
                    'studenten uit Brugge. Toen reed de bus door naar Kortrijk om daar de andere Howest studenten\n' +
                    'uit Kortrijk op te halen en de studenten van Devine. Toen begon de reis echt met eerst een\n' +
                    '6 uur lange busrit naar de luchthaven van Parijs. Waar we toen onze 12 uur lange vlucht\n' +
                    'moesten nemen naar San Francisco. Toen we na 12 uur landde in San Francisco was het nog\n' +
                    'steeds ochtend in de zelfde dag terwijl er vele uren gepasseerd waren, toch wel raar die\n' +
                    'jetlag. ',
                alineaText2: 'Het eerste wat we deden in San Francisco is een rit naar onze hostel, waar we onze bagage al\n' +
                    'konden plaatsen voor dat we begonnen aan stadswandeling om de buurt wat te verkennen. In\n' +
                    'deze tour hadden we voor het eerst de typische “cable cars” gebruikt. Dit is wel iets heel\n' +
                    'apart, het lijkt mij ook wel nog een moeilijke job voor de bestuurders. We liepen ook door\n' +
                    'typisch San Francisco-plaatsen met steile hellingen en mooie uitzichten. De wandeling was\n' +
                    'uiteindelijk naar een van de vele pieren, “Pier 39” dit is een pier met veel restaurants en\n' +
                    'entertainment. Naast de pier is ook een plaats waar zeeleeuwen zicht verzamelen.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag1/GroupPhotoUnionSquare.jpg", title:"Groepsfoto Union Square"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag1/ScenerySkyline2.jpg", title:"Skyline van San Francisco van ver"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag1/ScenerySkyline5.jpg", title:"James C. Flood Mansion en de Grace kathedraal"}
                ]
            },
            {
                alineaTitle: 'Dag 2',
                alineaText: 'De tweede dag startte met een wandeling over de Golden Gate Bridge. Op de brug hadden we\n' +
                    'echt mooie zichten van de stad met de skyline van alle skyscrapers. Na we de brug over\n' +
                    ' hadden gestoken zaten we weer op de bus richting Silicon Valley voor ons eerste\n' +
                    'bedrijfsbezoek naar Palo Alto Networks. Hier kregen we eerst een briefing over het bedrijf,\n' +
                    'hier leerden we dat Palo Alto een netwerk bedrijf is waar security vooral centraal staat.\n' +
                    'Hier kregen ook nog een paar medewerkers te horen, ze deelden met ons wat over hun werk\n' +
                    'ervaring en ook wat tips. Deze tips waren wel erg informatief omdat een deel ervan ging over\n' +
                    'het zoeken en solliciteren voor jobs en ook andere algemene tips dat handig zijn voor mensen\n' +
                    'in IT. Toen kregen we ook een rondleiding van de campus, dit was een erg grote campus met\n' +
                    'vele gebouwen en verschillende accommodaties zoals vele eetkraampjes, een fitness en yoga en\n' +
                    'sport lessen.',
                alineaText2: 'Na het eerste bedrijfsbezoek waren we even vrij om te eten. Toen hebben ik voor het eerst\n' +
                    'In-And-Out kunnen eten, ik had dit altijd al wel eens willen eten wat dit zag er als de\n' +
                    'beste burgers uit op sociale media. En het was totaal geen teleurstelling, de burgers waren\n' +
                    'zeer goed.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/SceneryGoldenGateBridge.jpg", title:"Golden Gate Bridge"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/PaloAltoGroup.jpg", title:"Palo Alto Networks groepsfoto"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/PaloAltoOffice1.jpg", title:"Palo Alto Networks campus"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/PaloAltoOffice2.png", title:"Palo Alto Networks Building 1"}
                ]
            },
            {
                alineaTitle: null,
                alineaText: 'Na de lunch zaten we weer op de bus naar ons tweede bedrijfsbezoek deze keer was dit\n' +
                    'Microsoft. In deze briefing dat we kregen ging het vooral over AI en low code. Hier waren\n' +
                    'ook veel vragen uit de groep, hierop hebben we veel nuttige antwoorden en tips gekregen over\n' +
                    'het bedrijfsleven later en de toekomst van IT. De vragenronde was zelf zo lang uitgelopen\n' +
                    'dat er geen tijd meer was voor een tour van de campus.',
                alineaText2: 'Na het bezoek van Microsoft waren we onderweg naar het Apple Visitor Center waar we een tour\n' +
                    'kregen met wat info over het Apple en hun campus. In de Visitor Center is er ook een\n' +
                    'miniatuur van de Apple campus waarmee je dan met een iPad kan op inzoomen in Augmented\n' +
                    'Reality.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftGroup.jpg", title:"Microsoft groepsfoto"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftOffice.jpg", title:"Microsoft campus"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftMeetingRoom.jpg", title:"Microsoft meeting room"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftSign.jpg", title:"Microsoft logo"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/AppleVisitor.jpeg", title:"Apple Visitor Center"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/AppleOffice.jpeg", title:"Apple campus"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/AppleVisitorAugmentedReality1.jpeg", title:"Apple miniatuur campus met AR"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/AppleVisitorAugmentedReality2.gif", title:"Apple miniatuur campus met AR"}
                ]
            },
            {
                alineaTitle: 'Dag 3',
                alineaText: 'De derde dag startte met een Alumni event waar enkele Belgen die verhuist zijn naar Amerika ' +
                    'hun ervaringen en tips deelden. De eerste spreker was Thomas, hij is een van de Belgen die ' +
                    'verhuist zijn naar de Verengde Staten om te werken in Silicon Valley voor OpenAI. Hij ' +
                    'vertelde zijn verhaal hoe dit allemaal gebeurd is en enkele tips die hij ons meegaf, die ' +
                    'hij zelf heeft ondervonden in zijn carrière.',
                alineaText2: 'De andere spreker die kwam is Christean, hij is ex Pinterest medewerker en werkt nu voor een ' +
                    'nieuwe startup genaamd Cambly. Hij deed ook zijn verhaal over zijn carrière en gaf ook wat ' +
                    'tips. Deze tips van beide waren wel erg interessant ze gingen voornamelijk over hoe je het ' +
                    'beste kan maken van je carrière, zoals dat een netwerk bouwen erg belangrijk is en dat zelf ' +
                    'geïnteresseerd zijn buiten het vak ook vele voordelen bied.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/SceneryPaintedLadies.jpg", title:"Painted Ladies"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/ScenerySkyline1.jpg", title:"San Francisco's skyline"}
                ]
            },
            {
                alineaTitle: null,
                alineaText: 'Achter de lunchpauze pakten we de BART naar Berkeley om de universiteit te bezoeken, ' +
                    'de BART is de naam van de metro lijnen is San Francisco. Op de universiteit kregen we ' +
                    'een rondleiding van enkele van de studenten die hier studeren. Dit was een erg grote campus ' +
                    'met vele grote gebouwen voor specifieke doelen zoals een gebouw voor de bibliotheek, een ' +
                    'voor “physiology”, een voor “engineering” en nog vele meer.',
                alineaText2: 'Dit was een erg mooie campus maar voor het prijskaartje van enkele tienduizenden dollar per ' +
                    'jaar zou ik het er persoonlijk niet voor overhebben. Toch wel blij dat het Belgische ' +
                    'onderwijs niet zo prijzig is.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley1.jpg", title:"California University at Berkeley"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley2.jpg", title:"California University at Berkeley"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley3.jpg", title:"California University at Berkeley"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley4.jpg", title:"California University at Berkeley"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley5.jpg", title:"California University at Berkeley"}
                ]
            },
            {
                alineaTitle: 'Dag 4',
                alineaText: 'We startte de vierde dag met een wandeling lang de Painted Ladies en dan nog een stop op de ' +
                    'Twin Peaks waarop je een mooie panorama zicht hebt over de hele stad. Hier waren we ook nog ' +
                    'even vrij om rond te lopen.',
                alineaText2: 'Hierna namen we terug de bus naar Silicon Valley, deze keer om Odoo te bezoeken. Dit is een ' +
                    'ERP systeem dat erg is geëvolueerd over de tijd. Het was oorspronkelijk een klein programma ' +
                    'maar is uit gegroeid tot een erg groot programma met veel integraties. Het is ook een ' +
                    'Belgisch bedrijf dat is gestart in Waterschei. Ook hier hebben we vele tips gekregen die ' +
                    'we later zullen kunnen gebruiken.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/ScenerySkyline2.jpg", title:"Skyline van San Francisco van ver"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/SceneryPaintedLadies.jpg", title:"Painted Ladies"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OdooOffice1.jpg", title:"Odoo kantoor"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OdooOffice2.jpg", title:"Odoo kantoor"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OdooSign.jpg", title:"Odoo logo"}
                ]
            },
            {
                alineaTitle: null,
                alineaText: 'Na de middag deden we nog een bedrijfsbezoek aan Oracle. Dit was een erg mooie campus met ' +
                    'veel gebouwen en ook een van de sportzeilboten op display. Hier kregen we een voorstelling ' +
                    'over Oracle en hun nieuwe low code software, waarmee je gemakkelijk web apps kan maken ' +
                    'zonder te programmeren.',
                alineaText2: 'Hierna deden we nog een wandeling door de Castro wijk en door Mission Dolores Park. ' +
                    'Dit is altijd leuk om nog wat van de voor ons nieuwe stad te verkennen.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/GroupPhotoOracle.jpg", title:"Groepsfoto Oracle"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OracleOffice1.jpg", title:"Oracle kantoor"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OracleOffice2.jpg", title:"Oracle kantoor"}
                ]
            },
            {
                alineaTitle: 'Dag 5',
                alineaText: 'De vijfde dag startte met een wandeling door de stad en een bezoek aan het Salesforce Park. ' +
                    'Daarna liep de wandeling ook langs het Oracle Park Stadium.',
                alineaText2: 'Hier in de buurt was ook ons volgende bedrijfsbezoek aan CloudFlare. Wat erg interessant is ' +
                    'hun manier om randomness te bepalen aan de hand van vele lavalampen. Hier kregen we ook een ' +
                    'office tour en ook een voorstelling over CloudFlare en hun services. Dit was ook erg ' +
                    'interessant en ook weer vele tips kunnen halen uit mensen met veel ervarging.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag5/CloudFlareLavalamps.jpg", title:"CloudFlare lavalampen"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag5/CloudFlareOffice1.jpg", title:"CloudFlare kantoor"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag5/CloudFlareOffice2.jpg", title:"CloudFlare kantoor"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag5/ScenerySkyline6.jpg", title:"Salesforce Park"}
                ]
            },
            {
                alineaTitle: 'Dag 6',
                alineaText: 'De zesde dag waren we vrij en hadden we besloten om een baseball game te gaan bekijken. ' +
                    'Deze match was de San Francisco Giants versus de Kansas City Royals. Dit was wel een erg ' +
                    'leuke ervaring die ik waarschijnlijk niet meer zal meemaken hier in Europa. Het is toch wel ' +
                    'iets anders dan voetbal.',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag6/BaseballOraclePark.jpg", title:"Oracle Park"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag6/BaseballPlayers.jpg", title:"Giants spelers"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag6/BaseballOracleParkVideo.mp4", title:"Baseball"}
                ]
            },
            {
                alineaTitle: 'Dag 7',
                alineaText: 'De zevende dag startte vroeg in de morgen met een ochtendwandeling met mezelf, het was namens ' +
                    'net de dag dat het San Francisco henge is. Dit is wanneer het pad van de zon op de zelfde ' +
                    'lijn loopt als de straten van het grid van San Francisco. Dit zorgde voor een paar mooie fotos.',
                alineaText2: 'Ook op deze dag deden we een bezoek aan Alcatraz een oude streng bewaakte gevangenis uit de ' +
                    'Verenigde Staten. Het is namelijk op een eiland gelegen zodanig de gevangenen goed vast ' +
                    'zitten. De gevangenis was erg groot met erg kleine cellen voor de gevangenen. We waren vrij ' +
                    'op het eiland om rond te lopen en verkennen. In het gevangenis gebouw was er ook een audiotour.',
                alineaImage: [
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/ScenerySunset1.jpg", title:"San Francisco Henge"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/ScenerySunset2.jpg", title:"San Francisco Henge"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/AlcatrazIsland1.jpg", title:"Alcatraz gevangenis eiland"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/AlcatrazIsland2.jpg", title:"Alcatraz gevangenis eiland"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/AlcatrazMiniature.jpg", title:"Alcatraz miniatuur"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/AlcatrazPrisonEntrance.jpg", title:"Alcatraz gevangenis ingang"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/ChinatownStreet.jpg", title:"Chinatown"},
                    {url:"/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/ChinatownBuilding.jpg", title:"Chinatown"}
                ]
            }
        ]
    },
    {
        id: 4,
        name: {
            'nl': "Slim licht en security systeem",
            'eng': "Smart light and security system"
        },
        category: {
            'nl': "In Development",
            'eng': "In Development"
        },
        date: {
            'nl': "September 2022 - Januari 2023",
            'eng': "September 2022 - January 2023"
        },
        thumbnail: "/blogImages/SmartHomeLights/thumbnail.jpg",
        alineas: [
            {
                alineaTitle: 'Inleiding',
                alineaText: 'Voor de module “Cloud and edge computing” word er ons gevraagd een slim “Internet of ' +
                    'Things” device te maken dat ook verbonden is met een Cloud systeem. Hiervoor zit ik in de groep ' +
                    'met João Vanso Do Prado en Roel Spiessens.',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/SmartHomeLights/Wat_is_een_smart_home.jpg", title:"Smart lichten met app"}
                ]
            },
            {
                alineaTitle: 'Ons project voorstel',
                alineaText: 'Dit project moest ook voldoen aan enkele benodigdheden qua programmeertaal, technologie, ' +
                    'communicatie en cloud systeem, zoals wifi communicatie en een andere vorm van draadloze ' +
                    'communicatie, 3 verschillende types sensors, een type data die altijd gemonitord moet blijven, ' +
                    'een evenement getriggerd door data, user en cloud platform, enkele commutaties moeten gebeuren op ' +
                    'het cloud platform en de code moet in C of Python.',
                alineaText2: 'Om aan deze benodigdheden te voldoen had mijn groepje en ik het idee om een slim licht' +
                    ' systeem te maken, maar dit had een probleem dit voldeed niet aan alle benodigdheden. Maar toen ' +
                    'kwam het idee om ook een security systeem in ons systeem in te werken.',
                alineaImage: [
                    {url:"/blogImages/SmartHomeLights/LENL_Categoriebanners_848x260_Slimme_lichtbronnen.png", title:"Smart lichten met app"}
                    ]
            },
            {
                alineaTitle: null,
                alineaText: 'Op ons systeem zal een user van allerlei dingen kunnen doen via de “progressive web app” ' +
                    'zoals de lichten kunnen bedienen en een timer in stellen om aan of uit te gaan, activeren of de ' +
                    'lichten aan of uit gaan met de beweging sensor, het alarm activeren die er voor zorgt als er ' +
                    'beweging of geluid word gedetecteerd dat een buzzer afgaat en een notificatie naar de app ' +
                    'gestuurd word.',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/SmartHomeLights/glamour.jpg", title:"Ledstrip"}
                ]
            },
            {
                alineaTitle: 'Ons project',
                alineaText: 'Als cloud platform werd ons Azure Cloud gekozen. Eerst hadden we dit geprobeerd met “IoT ' +
                    'hub” van Azure maar dit kregen we dit jammer genoeg niet werkende. En toen kozen we voor te ' +
                    'werken met een zelf geprogrammeerde Python server en deze te hosten op Azure. En voor de website ' +
                    'maakten we een standaard HTML, CSS en Javascript website.',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/SmartHomeLights/Raspberry Pi-PI4 MODEL B_1GB-30152779-01.jpg", title:"Raspberry PI"}
                    ]
            }
        ]
    },
    {
        id: 5,
        name: {
            'nl': "Marswin: goksite AI race simulatie",
            'eng': "Marswin: bettingsite AI race simulation”"
        },
        category: {
            'nl': "In Development",
            'eng': "In Development"
        },
        date: {
            'nl': "September 2022 - December 2022",
            'eng': "September 2022 - December 2022"
        },
        thumbnail: "/blogImages/MarsWin/unnamed.png",
        alineas: [
            {
                alineaTitle: 'De opdracht',
                alineaText: 'Voor de module “Trending topic” is de opdracht voor het project dat iedereen elk met een technologie moet werken dat hij nog nooit heeft gebruikt. Hiervoor zit ik in de groep met Aico Mylleville, Daan Vander Haegen, Jonathan Depaepe en Nicolas Loontjens. ',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/MarsWin/unnamed.png", title:"Start opstelling race"},
                    {url:"/blogImages/MarsWin/X2Download.app-Unity AI Progression-(1080p60).mp4", title:"AI training"}
                ]
            },
            {
                alineaTitle: 'Ons project voorstel',
                alineaText: 'Wij hadden hiervoor het idee om een gok site en app te maken waren de gebruikers weddenschappen kunnen plaatsen op AI auto’s die racen tegen elkaar. De verdeling van het werk onder onze groep gaat als volgt Jonathan maakt de website met NextJS, Nicolas maakt de app met flutter, Aico is verantwoordelijk voor het maken van de API in GO, Ik maak een game in Unity waarin Daan verantwoordelijk is om een AI te maken die er voor zorgt dat de auto zelf tegen elkaar racen.',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/MarsWin/unnamed (1).png", title:"In-game cybertruck"},
                    {url:"/blogImages/MarsWin/unnamed (2).png", title:"Maan racetrack"},
                ]
            },
            {
                alineaTitle: 'Ons project',
                alineaText: 'Dit project verliep erg goed en er is een erg mooi resultaat van gekomen waar we allen trots op zijn. Het was enkele weken hard werken om het toch volledig werkende te krijgen maar gelukkig was dit uiteindelijk gelukt. Dit ging zeker niet gelukt zijn zonder de andere van de groep die zorgden voor een goede samenwerking en groepssfeer. ',
                alineaText2: null,
                alineaImage: [
                    {url:"/blogImages/MarsWin/unnamed (3).png", title:"AI in-game sensors"},
                    {url:"/blogImages/MarsWin/web-video.mp4", title:"Flutter app demo"}
                ]
            }
        ]
    }
];
export const headerText =
    {
        headerGreeting: {
            'nl': "Goeiedag, ik ben",
            'eng': "Nice to meet u, I'm"
        },
        headerName: "Aron Buffel",
        headerProffesion: "Student Software Engineering",
        headerScroll: {
            nl: "Naar onder",
            eng: "Scroll down"
        },headerCV: {
            nl: "Download mijn CV",
            eng: "Download my CV"
        },headerContact: {
            nl: "Contacteer mij",
            eng: "Contact me"
        }
    };

export const aboutText =
    {
        aboutTitleSmall: {
            'nl': "Kom meer te weten",
            'eng': "Get to know more"
        },
        aboutTitle: {
            'nl': "Over Mij",
            'eng': "About Me"
        },
        aboutTile1Title:{
            'nl': "Educatie",
            'eng': "Education"
        },
        aboutTile1Text:{
            'nl': "Bachelor in TI: Software Engineering aan de HOWEST in Brugge",
            'eng': "Bachelor in TI: Software Engineering at HOWEST Bruges"
        },
        aboutTile2Title:{
            'nl': "Functie",
            'eng': "Position"
        },
        aboutTile2Text:{
            'nl': "Op zoek naar job als Full-Stack Developer",
            'eng': "Searching for a job as Full-Stack Developer"
        },
        aboutTile3Title:{
            'nl': "Interesses",
            'eng': "Intressions"
        },
        aboutTile3Text:{
            'nl': "React, NodeJS, Python, Java, .NET",
            'eng': "React, NodeJS, Python, Java, .NET"
        },aboutText1:{
            'nl': "Goeiedag, mijn naam is Aron Buffel. Ik ben momenteel student aan Howest in Brugge, waar ik de " +
                "opleiding TI Software Engineering volg. Programmeren is altijd al een passie van mij geweest en ik heb " +
                "de afgelopen jaren veel ervaring opgedaan met verschillende programmeertalen en technologieën.",
            'eng': "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo consequuntur, praesentium placeat, " +
                "voluptatibus ut mollitia tempora eos officiis veritatis voluptatem sit nesciunt! Earum, commodi at "
        },aboutText2:{
            'nl': "Gedurende mijn studie heb ik al veel verschillende projecten uitgevoerd, zowel op het gebied van " +
                "front-end als back-end development. Ik vind het leuk om uitdagende problemen op te lossen en mooie, " +
                "functionele interfaces te ontwerpen. Ik heb ervaring met HTML, CSS, JavaScript, React voor de front-end " +
                "en met Java, C#, NodeJS en Python voor de back-end. ",
            'eng': "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo consequuntur, praesentium placeat, "
        },aboutText3:{
            'nl': "Mijn interesse in zowel front-end als back-end development heeft mij doen besluiten dat ik mij wil " +
                "richten op een functie als Full-stack developer. Ik geloof dat mijn kennis en vaardigheden mij goed " +
                "zullen positioneren voor deze rol en ik kijk ernaar uit om mijn vaardigheden te blijven ontwikkelen " +
                "en uit te breiden.",
            'eng': "elit. Suscipit quam dolore ad provident eaque culpa ut, modi commodi aut exercitationem aspernatur " +
                "doloremque quasi amet nemo laboriosam ab deleniti vitae fugit. Lorem, ipsum dolor sit amet consectetur "

        },aboutText4:{
            'nl': "Naast mijn studie en mijn interesse voor programmeren, houd ik van fitnessen, koersfietsen en reizen. " +
                "Ik ben een enthousiaste en gedreven persoon die altijd op zoek is naar nieuwe uitdagingen en kansen " +
                "om te groeien.",
            'eng': "adipisicing elit. Officia excepturi alias voluptatum deleniti nesciunt non repellendus laboriosam quod! " +
                "Nesciunt reiciendis similique repellendus laboriosam velit eius est necessitatibus pariatur fugit sit!"
        },aboutContact:{
            'nl': "Contacteer mij",
            'eng': "Let's talk"
        }
    };

export const experienceText =
    {
        experienceTitleSmall: {
            'nl': "Welke skills heb ik",
            'eng': "Which skills do I have"
        },
        experienceTitle: {
            'nl': "Mijn Ervaring",
            'eng': "My Experience"
        },
        frontEnd:[
            {
                name:"HTML",
                levelText:{
                    'nl': "Vaardig",
                    'eng': "Proficient"
                },
                level:"90%"
            },
            {
                name:"CSS",
                levelText:{
                    'nl': "Vaardig",
                    'eng': "Proficient"
                },
                level:"90%"
            },
            {
                name:"JavaScript",
                levelText:{
                    'nl': "Ervaren",
                    'eng': "Proficient"
                },
                level:"75%"
            },
            {
                name:"Laravel",
                levelText:{
                    'nl': "Gemiddeld",
                    'eng': "Proficient"
                },
                level:"50%"
            },
            {
                name:"React",
                levelText:{
                    'nl': "Bekwaam",
                    'eng': "Skilled"
                },
                level:"65%"
            }
        ],
        backEnd:[
            {
                name:"Java",
                levelText:{
                    'nl': "Ervaren",
                    'eng': "Proficient"
                },
                level:"75%"
            },
            {
                name:"Python",
                levelText:{
                    'nl': "Ervaren",
                    'eng': "Proficient"
                },
                level:"75%"
            },
            {
                name:"NodeJS",
                levelText:{
                    'nl': "Bekwaam",
                    'eng': "Skilled"
                },
                level:"65%"
            },
            {
                name:".NET",
                levelText:{
                    'nl': "Bekwaam",
                    'eng': "Skilled"
                },
                level:"65%"
            }
        ]

    };

export const portfolioText =
    {
        portfolioTitleSmall: {
            'nl': "Lees meer over mijn IT ervaringen",
            'eng': "Read more about my IT experiences"
        },
        portfolioTitle: {
            'nl': "Mijn Blog",
            'eng': "My Blog"
        },
        portfolioMore: {
            'nl': "Lees meer",
            'eng': "Read more"
        }
    };

export const contactText =
    {
        contactTitleSmall: {
            'nl': "Neem contact op",
            'eng': "Get in touch with me"
        },
        contactTitle: {
            'nl': "Contacteer Mij",
            'eng': "Contact Me"
        },
        contactEmailTitle: "E-mail",
        contactEmailWork: "aron.buffel\n@student.howest.be",
        contactEmailPersonal: "aron.buffel@telenet.be",
        contactPhoneTitle: {
            'nl': "Telefoon",
            'eng': "Telephone"
        },
        contactPhone: "+32 471 62 21 67",
        contactLinkedinTitle: "LinkedIn",
        contactLinkedin: "linkedin.com/in/buffel-aron",
    };

export const footerNavText =
    {
        home: {
            'nl': "Home",
            'eng': "Home"
        },
        about: {
            'nl': "Over Mij",
            'eng': "My Blog"
        },
        experience: {
            'nl': "Mijn ervaring",
            'eng': "My Experience"
        },
        portfolio: {
            'nl': "Mijn Blog",
            'eng': "My Blog"
        }
        ,contact: {
            'nl': "Contact",
            'eng': "Contact"
        }
    };

function App() {
    const [language, setLanguage] = useState('nl');
    const [lightMode, setLightMode] = useState(false);
    return (
        <BrowserRouter>
            <Routes>
                {posts.map((post) => (
                    <Route
                        key={post.id}
                        path={`/blog/${post.id}`}
                        element={<BlogPage post={post} footerNavText={footerNavText} language={language}
                                           setLanguage={setLanguage} lightMode={lightMode} setLightMode={setLightMode}/>}
                    />
                ))}
                <Route path="/icon" element={<Icon/>} />
                <Route path='*' element={<NotFound/>}/>
                <Route path="/" element={<Home language={language} setLanguage={setLanguage} posts={posts}
                                               lightMode={lightMode} setLightMode={setLightMode} headerText={headerText}
                                               aboutText={aboutText} experienceText={experienceText} portfolioText={portfolioText}
                                               contactText={contactText} footerNavText={footerNavText}/>} />
            </Routes>
        </BrowserRouter>
    );
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Render your app inside the root
root.render(<App />);


